<template>
  <not-authorised>
    <template #left-half>
      <register-form class="register-block"/>
    </template>
    <template #right-half>
      <div class="right-unauthorised-block">
        <div class="title is-3-5">Уже есть аккаунт? </div>
        <p class="common-text">Войдите в систему, если вы уже зарегистрированы.</p>
        <router-link to="/login" class="button is-normal main-button purple">
          <span class="icon is-small">
            <user-icon color="#FFFFFF"/>
          </span>
          <span>Войти в систему</span>
        </router-link>
      </div>
    </template>
  </not-authorised>
</template>

<script>
import NotAuthorised from '../components/common/NotAuthorised.vue'
import RegisterForm from '../components/common/RegisterForm.vue'
import UserIcon from '../components/icons/UserIcon.vue'
export default {
  components: { NotAuthorised, RegisterForm, UserIcon },
  name: 'sugnUp'
}
</script>

<style lang="scss" scoped>
.register-block {
  width: 50%;
}
</style>