<template>
  <div class="form">
    <div class="logo">
      <logo />
    </div>
    <div class="title is-3-5 mb-1">Регистрация в системе</div>

    <div v-if="errors" class="errors is-size-7-2">
      <p
        v-for="(error, field) in errors"
        :key="field"
        class="mb-3 has-text-danger"
      >
        {{ error[0] }}
      </p>
    </div>

    <tnd-input
      class="form__input mt-2"
      placeholder="Ваше имя"
      v-model="formValues.username"
    >
      <template #before-input>
        <user-icon />
      </template>
    </tnd-input>
    <tnd-input
      class="form__input"
      placeholder="Ваш E-Mail"
      type="email"
      v-model="formValues.email"
    >
      <template #before-input>
        <mail-icon />
      </template>
    </tnd-input>
    <tnd-input
      class="form__input"
      placeholder="Ваш пароль"
      type="password"
      v-model="formValues.password"
    >
      <template #before-input>
        <locker-icon />
      </template>
    </tnd-input>
    <button
      @click.prevent="register"
      class="button is-normal main-button purple"
    >
      <span class="icon is-small">
        <user-add-icon />
      </span>
      <span>Регистрация в системе</span>
    </button>
    <a href="/" class="common-link">Перейти на главную</a>
  </div>
</template>

<script>
import LockerIcon from "../icons/LockerIcon.vue";
import Logo from "../icons/Logo.vue";
import MailIcon from "../icons/MailIcon.vue";
import UserAddIcon from "../icons/UserAddIcon.vue";
import UserIcon from "../icons/UserIcon.vue";
import TndInput from "./TndInput.vue";

import authService from "../../services/auth.service";

export default {
  components: { Logo, TndInput, UserIcon, LockerIcon, MailIcon, UserAddIcon },
  data: () => ({
    formValues: {
      username: "",
      email: "",
      password: "",
      password_repeat: "",
    },
    errors: null,
  }),
  methods: {
    async register() {
      this.errors = null;

      this.formValues.password_repeat = this.formValues.password;

      const { success, errors } = await authService.register(this.formValues);
      if (success) {
        this.$buefy.toast.open({
          message: "Регистрация пройдена успешно",
          type: "is-success",
          duration: 3000
        });

        this.$router.push({ name: "login" });
      } else {
        this.errors = errors;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form {
  min-width: 50%;
  display: flex;
  flex-direction: column;

  .title {
    margin-top: 75px;
  }

  &__input:first {
    margin-top: 28px;
  }

  &__input + &__input {
    margin-top: 6px;
  }

  .button {
    margin-top: 32px;
  }

  .common-link {
    margin-top: 77px;
  }
}
</style>